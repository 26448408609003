import React from "react"
import { graphql } from "gatsby"
import MyHelmet from "../components/MyHelmet"
import InfosPageTemplate from "./InfosPageTemplate"

const InfosPage = ({ data }) => {
  const { frontmatter: fm } = data.markdownRemark
  const { edges: infos } = data.allMarkdownRemark

  return (
    <>
      <MyHelmet title={fm.title} description={fm.subheading} />
      <InfosPageTemplate
        heading={fm.heading}
        subheading={fm.subheading}
        image={fm.image}
        infos={infos}
      />
    </>
  )
}

export default InfosPage

export const infosPageQuery = graphql`
  query InfosPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        subheading
        image {
          childImageSharp {
            gatsbyImageData(width: 1216, placeholder: BLURRED)
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fields: { infoCurrent: { eq: true } }
        frontmatter: { templateKey: { eq: "info-page" } }
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "DD. MMMM YYYY", locale: "de-DE")
            icon
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 640
                  placeholder: BLURRED
                  aspectRatio: 1.5
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
  }
`
