import React from "react"
import Header from "../components/Header"
import { Container, Hero, CardSet } from "../components/Sections"

function InfosPageTemplate({ heading, subheading, image, infos = [] }) {
  return (
    <div>
      <Header heading={heading} subheading={subheading} />
      <Hero image={image}/>
      <Container>
        <CardSet infos={infos} />
      </Container>
    </div>
  )
}

export default InfosPageTemplate
